import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
// import success from "../../assets/img/success.svg";
import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import { images } from "./data";
import Gallery from "react-grid-gallery";
import Parallax from "components/Parallax/Parallax.js";
import { Button } from "@material-ui/core";
import { colorModePrimaryText, colorModePrimaryBackGround, colorModeSecondaryText } from "assets/jss/material-kit-pro-react.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


const styles = {
  mainRaised: {
    "@media (max-width: 576px)": {
      marginTop: "-30px"
    },
    "@media (max-width: 830px)": {
      marginLeft: "10px",
      marginRight: "10px"
    },
    margin: "-60px 30px 30px",
    borderRadius: "6px",
    boxShadow: "1px 1px 9px 1px #00000026"
  },
  main: {
    background: "white",
    position: "relative",
    zIndex: "3"
  },
  mainDiv: {
    minHeight: "100vh",
    backgroundColor: "white"
  },
  container: {
    paddingTop: "50px",
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
  description: {
    textAlign: "center"
  },
  title: {
    marginBottom: 50,
    letterSpacing: "4px !important",
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "30px",
    marginBottom: "25px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center"
  },
  buttonDiv: {
    marginTop: "50px",
    display: "flex",
    justifyContent: "center"
  },
  colorModePrimaryText: {
    ...colorModePrimaryText
  },
  colorModePrimaryBackGround: {
    ...colorModePrimaryBackGround
  },
  colorModeSecondaryText: {
    ...colorModeSecondaryText
  }
};

class OurWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all: this.props.imagesAll,
      architecture: this.props.architecture,
      industrial: this.props.industrial,
      isAll: true,
      isArchitecture: false,
      isIndustrial: false
    };
  }

  setCustomTags(i) {
    return i.tags.map(t => {
      return (
        <div key={t.value} style={customTagStyle}>
          {t.title}
        </div>
      );
    });
  }

  all = () => {
    this.setState({
      isAll: true,
      isArchitecture: false,
      isIndustrial: false
    });
  };

  architecture = () => {
    this.setState({
      isAll: false,
      isArchitecture: true,
      isIndustrial: false
    });
  };

  industrial = () => {
    this.setState({
      isAll: false,
      isArchitecture: false,
      isIndustrial: true
    });
  };

  render() {
    const { isAll, isArchitecture, isIndustrial } = this.state;
    const { classes } = this.props;
    var imagesAll = this.state.all.map(i => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {i.hasOwnProperty("tags") && this.setCustomTags(i)}
        </div>
      );
      return i;
    });
    var architecture = this.state.architecture.map(i => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {i.hasOwnProperty("tags") && this.setCustomTags(i)}
        </div>
      );
      return i;
    });
    var industrial = this.state.industrial.map(i => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {i.hasOwnProperty("tags") && this.setCustomTags(i)}
        </div>
      );
      return i;
    });

    return (
      <div>
        <Header
          links={<HeaderLinks changeColorOnScroll={{
            height: 100,
            color: "white"
        }} dropdownHoverColor="info" />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 100,
            color: "black"
          }}
        />
        <Parallax
          image={require("assets/img/artwork.jpg")}
          className={classes.parallax}
        />
        <div className={classes.mainRaised + " " + classes.main + ' ' + classes.colorModePrimaryBackGround }>
          <div className={classes.container}>
            <h2 className={classes.title + ' ' + classes.colorModePrimaryText}> Portfolio </h2>
            <h4 className={classes.description + ' ' + classes.colorModeSecondaryText}>
              "We believe that strength in architecture emerges when there is an emotional connect to the work.
                The buildings must respond to the land, go beyond concepts, geometry, forms and symmetry, while incorporating continuity and change"
            </h4>
            <div className={classes.buttonDiv}>
              <Button onClick={this.all} className={classes.colorModePrimaryText}>All</Button>
              <Button onClick={this.architecture} className={classes.colorModePrimaryText}>Day Dreams</Button>
              <Button onClick={this.industrial} className={classes.colorModePrimaryText}>Drawings</Button>
              <Button onClick={this.industrial} className={classes.colorModePrimaryText}>Paintings</Button>
              <Button onClick={this.industrial} className={classes.colorModePrimaryText}>Renderings</Button>
              <Button onClick={this.industrial} className={classes.colorModePrimaryText}>Residential Design</Button>
              <Button onClick={this.industrial} className={classes.colorModePrimaryText}>Gabriel Prize</Button>
            </div>
            <div style={{ paddingTop: "30px", paddingBottom: "50px" }}>
              <div
                style={{
                  display: "block",
                  minHeight: "1px",
                  width: "100%",
                  overflow: "auto"
                }}
              >
                {isAll ? (
                  <Gallery images={imagesAll} enableImageSelection={false} />
                ) : (
                  <> </>
                )}
                {isArchitecture ? (
                  <Gallery images={architecture} enableImageSelection={false} />
                ) : (
                  <> </>
                )}
                {isIndustrial ? (
                  <Gallery images={industrial} enableImageSelection={false} />
                ) : (
                  <> </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer
                content={
                    <div>
                        <GridContainer>
                            <GridItem sm={6} xs={12} md={9}>
                                <p>
                                {/* Copyright 2019, All rights reserved */}
                                </p>
                            </GridItem>
                            <GridItem sm={6} xs={12} md={3}>
                                <p className={classes.colorModePrimaryText}>
                                Powered by <a  target="_Blank" href="http://nceptio.com" alt="Best Web Developers" className={classes.colorModePrimaryText}> Nceptio <img src="/nceptio_logo2.png" style={{ marginLeft: 10, width: 85}} /></a>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </div>
                }
            />
      </div>
    );
  }
}

OurWork.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      srcset: PropTypes.array,
      caption: PropTypes.string,
      thumbnailWidth: PropTypes.number.isRequired,
      thumbnailHeight: PropTypes.number.isRequired
    })
  ).isRequired
};

const captionStyle = {
  backgroundColor: "rgba(0, 0, 0, 0.8)",
  maxHeight: "240px",
  overflow: "hidden",
  position: "absolute",
  bottom: "0",
  width: "100%",
  color: "white",
  padding: "2px",
  fontSize: "90%"
};

const customTagStyle = {
  wordWrap: "break-word",
  display: "inline-block",
  backgroundColor: "white",
  height: "auto",
  fontSize: "75%",
  fontWeight: "600",
  lineHeight: "1",
  padding: ".2em .6em .3em",
  borderRadius: ".25em",
  color: "black",
  verticalAlign: "baseline",
  margin: "2px"
};

OurWork.defaultProps = {
  imagesAll: [
    {
      src: "assets/banner/daydreams_1.jpg",
      thumbnail: "assets/banner/daydreams_1.jpg",
      thumbnailWidth: 271,
      thumbnailHeight: 320,
      tags: [{ value: "DayDream", title: "DayDream" }],
      caption: "DayDream"
    },
    {
      src: "assets/banner/daydreams_2.jpg",
      thumbnail: "assets/banner/daydreams_2.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 190,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_1900Elm_1.jpg",
      thumbnail: "assets/banner/daydreams_1900Elm_1.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 148,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_1900Elm_2.jpg",
      thumbnail: "assets/banner/daydreams_1900Elm_2.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 213,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_DavisBldgColl.jpg",
      thumbnail: "assets/banner/daydreams_DavisBldgColl.jpg",
      thumbnailWidth: 248,
      thumbnailHeight: 320,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_DavisBldgColl.jpg",
      thumbnail: "assets/banner/daydreams_DavisBldgColl.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 113,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_dMainStreetCrane.jpg",
      thumbnail: "assets/banner/daydreams_dMainStreetCrane.jpg",
      thumbnailWidth: 313,
      thumbnailHeight: 320,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_EDavis2.jpg",
      thumbnail: "assets/banner/daydreams_EDavis2.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 213,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_EDavis2.jpg",
      thumbnail: "assets/banner/daydreams_EDavis2.jpg",
      thumbnailWidth: 271,
      thumbnailHeight: 320,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_Inversion.jpg",
      thumbnail: "assets/banner/daydreams_Inversion.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 190,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_JouleEye.jpg",
      thumbnail: "assets/banner/daydreams_JouleEye.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 148,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_KirbyBldg.jpg",
      thumbnail: "assets/banner/daydreams_KirbyBldg.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 213,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    }
  ],
  architecture: [
    {
      src: "assets/banner/daydreams_Lark1.jpg",
      thumbnail: "assets/banner/daydreams_Lark1.jpg",
      thumbnailWidth: 271,
      thumbnailHeight: 320,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_MuseoCapitolini.jpg",
      thumbnail: "assets/banner/daydreams_MuseoCapitolini.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 190,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_oMarcellus004.jpg",
      thumbnail: "assets/banner/daydreams_oMarcellus004.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 148,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_PalazzoBorghese.jpg",
      thumbnail: "assets/banner/daydreams_PalazzoBorghese.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 213,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_pCapitolineHill002.jpg",
      thumbnail: "assets/banner/daydreams_pCapitolineHill002.jpg",
      thumbnailWidth: 248,
      thumbnailHeight: 320,
      tags: [{ value: "Architecture", title: "Architecture | Outdoors" }],
      caption: "Architecture"
    }
  ],
  industrial: [
    {
      src: "assets/banner/daydreams_progress1.jpg",
      thumbnail: "assets/banner/daydreams_progress1.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 113,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    },
    {
      src: "assets/banner/daydreams_WilsonBldg.jpg",
      thumbnail: "assets/banner/daydreams_WilsonBldg.jpg",
      thumbnailWidth: 313,
      thumbnailHeight: 320,
      tags: [{ value: "Industrial", title: "Industrial" }],
      caption: "Architecture"
    }
  ]
};

export default withStyles(styles)(OurWork);
