import React from "react";
// nodejs library that concatenates classes
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";

// import bg from "assets/img/examples/card-project3.jpg";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(cardsStyle);

export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classes.sectionStyle}
      style={{ backgroundImage: `url(${require("assets/img/image--.jpg")})`, backgroundAttachment: 'fixed' }}
    >
      <div className={classes.divWidth}>
        <GridContainer justify="center" spacing={3}>
          <GridItem md={5} sm={5}>
            <div className={classes.divBgStyle + ' ' + classes.colorModeSecondaryBackGround}>             
              <h2 className={classes.colorModeBasicText + ' ' + classes.title}> Jay Cantrell Designs </h2>
              <h5 className={classes.colorModeBasicText}>
              {/* We create spaces considering functionality & form to achieve aesthetics to satisfy the requirement of the project. The design integrates the cutting edge technology with discreet use of ecofriendly materials. Our design blends art and architecture, light and shadow, texture and colour to add value to the project by creating an ambience that projects the image of the client. Our design process addresses the major issues of budget, timeframe and best available technology as required for the specific project. */}
              Artist & Architect<br />
              Dallas, Texas
              </h5>
              <div style={{ width: '100%', textAlign: 'right' }}>
              <Button
                  href="/about-us"
                  variant="contained"
                  size="medium"
                  className={classes.buttonCard + ' ' + classes.colorModePrimaryBackGround + ' ' + classes.colorModePrimaryText}                                  
                 >
                    About
                </Button>
                </div>
            </div>
          </GridItem>
          <GridItem md={7} sm={7}>

          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
