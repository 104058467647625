import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
// import Apps from "@material-ui/icons/Apps";
// import ViewDay from "@material-ui/icons/ViewDay";
// import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import img5 from "assets/img/img5.jpg";
import img6 from "assets/img/img6.jpg";
import img7 from "assets/img/img7.jpg";
import img8 from "assets/img/img8.jpg";
import img9 from "assets/img/img9.jpg";
import img10 from "assets/img/img10.jpg";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingTop: '30px' }}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description  + ' ' + classes.colorModeSecondaryText}>
              "We create spaces considering functionality & form to achieve aesthetics to satisfy the requirement of the project. The design integrates the cutting edge technology with discreet use of ecofriendly materials. Our design blends art and architecture, light and shadow, texture and colour to add value to the project by creating an ambience that projects the image of the client. Our design process addresses the major issues of budget, timeframe and best available technology as required for the specific project."
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <h2 className={classes.title + ' ' + classes.colorModePrimaryText}> Recent Projects </h2>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <div className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Dallas"
                description="Dallas, a modern metropolis in north Texas, is a commercial and cultural hub of the region."
                icon={img5}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div
              className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Austin"
                description="Austin is the state capital of Texas, an inland city bordering the Hill Country region."
                iconColor="rose"
                icon={img6}
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Houston"
                description="Houston is a large metropolis in Texas, extending to Galveston Bay."
                icon={img7}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Frisco"
                description="Frisco is a city in Texas and part of the Dallas-Fort Worth metro area."
                icon={img8}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Denton"
                description="Denton is a city in Texas within the Dallas-Fort Worth metro area."
                icon={img9}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
            <GridItem md={4} sm={4}>
              <div 
              className={classes.customCardLayout + ' ' + classes.customCardLayoutDark}>
              <InfoArea
                title="Waco"
                description="The Waco siege was the siege of a compound belonging to the religious sect Branch Davidians."
                icon={img10}
                iconColor="rose"
                vertical={true}
                isDesc={true}
              />
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
