
/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";
import tab1Image from 'assets/img/artwork.jpg';
import tab2Image from 'assets/img/renderings.jpg';
import tab3Image from 'assets/img/walking_tours.jpg';


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                brand="Material Kit PRO React"
                links={<HeaderLinks changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }} dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "black"
                }}
            />
            <Parallax
                image={tab3Image}
                className={classes.parallax}
            />

            <div className={classNames(classes.main, classes.mainRaised, classes.colorModePrimaryBackGround)} style={{ paddingBottom: 50 }}>
                <div className={classes.container}>
                    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
                        <h2 className={classes.title + ' ' + classes.colorModePrimaryText}> Walking Tours </h2>
                        <GridContainer>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing className={classes.colorModeSecondaryBackGround}>
                                    <img src={tab3Image} className={classes.tabImage}/>                                                                      
                                    {/* <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5> */}
                                    <h5 className={classes.cardCategory} ><b>Basic:</b> $20/person</h5>
                                    <h5 className={classes.cardCategory} ><b>Private Tour:</b> $100/up to 4 people</h5>
                                    <h5 className={classes.cardCategory} ><b>1/2 day Tour + Meal:</b> $60/person</h5>
                                    
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter + ' ' + classes.colorModeSecondaryBackGround}>
                                    <Button  round className={classes.colorModePrimaryBackGround + ' ' + classes.colorModePrimaryText}>
                                    Book now
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing className={classes.colorModeSecondaryBackGround}>
                                    <img src={tab3Image} className={classes.tabImage}/>                                                                      
                                    {/* <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5> */}
                                    {/* <h6 className={classes.cardCategory} style={{ fontWeight: 'normal' }}>Basic $20/person <br />
                                        Private Tour: $100/up to 4 people <br />
                                        1/2 day Tour + Meal $60/person</h6> */}

                                    <h5 className={classes.cardCategory} ><b>Basic:</b> $20/person</h5>
                                    <h5 className={classes.cardCategory} ><b>Private Tour:</b> $100/up to 4 people</h5>
                                    <h5 className={classes.cardCategory} ><b>1/2 day Tour + Meal:</b> $60/person</h5>
                                    
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter + ' ' + classes.colorModeSecondaryBackGround}>
                                    <Button round className={classes.colorModePrimaryBackGround + ' ' + classes.colorModePrimaryText}>
                                    Book now
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4}>
                            <Card pricing raised>
                                <CardBody pricing className={classes.colorModeSecondaryBackGround}>
                                    <img src={tab3Image} className={classes.tabImage}/>                                                                      
                                    {/* <h4 className={classes.cardCategory}>Dallas</h4>
                                    <h5 className={classes.cardCategory} style={{ fontWeight: '700' }}>price : $192,500</h5> */}
                                    <h5 className={classes.cardCategory} ><b>Basic:</b> $20/person</h5>
                                    <h5 className={classes.cardCategory} ><b>Private Tour:</b> $100/up to 4 people</h5>
                                    <h5 className={classes.cardCategory} ><b>1/2 day Tour + Meal:</b> $60/person</h5>
                                    
                                </CardBody>
                                <CardFooter pricing className={classes.justifyContentCenter + ' ' + classes.colorModeSecondaryBackGround}>
                                    <Button round className={classes.colorModePrimaryBackGround + ' ' + classes.colorModePrimaryText}>
                                    Book now
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        </GridContainer>
                    </div>
                </div>
                
            </div>
            <Footer
                content={
                    <div>
                        <GridContainer>
                            <GridItem sm={6} xs={12} md={9}>
                                <p>
                                {/* Copyright 2019, All rights reserved */}
                                </p>
                            </GridItem>
                            <GridItem sm={6} xs={12} md={3}>
                                <p className={classes.colorModePrimaryText}> 
                                Powered by <a  target="_Blank" href="http://nceptio.com" alt="Best Web Developers" className={classes.colorModePrimaryText}> Nceptio <img src="/nceptio_logo2.png" style={{ marginLeft: 10, width: 85}} /></a>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </div>
                }
            />
        </div>
    );
}
