/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Edit from "@material-ui/icons/Edit";

import Button from "components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle";
import { ListItemText } from "@material-ui/core";


const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {

  const [scrollE, setScrollE] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const headerColorChange = () => {
    const { changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      console
      setScrollE(true);
    } else {
      setScrollE(false);
    }
  };


  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto + " " + classes.headerLink}>
      <ListItem className={classes.listItem}>
        <Link to="/about-us" className={classes.dropdownLink}>
          {/* {scrollE ? (
            <ListItemText style={{ marginRight: '15px'  }}> About </ListItemText>
          ):( */}
            <ListItemText style={{ marginRight: '15px', color:'#fff'  }}> About </ListItemText>
          {/* )} */}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/portfolio" className={classes.dropdownLink}>
        {/* {scrollE ? (
            <ListItemText style={{ marginRight: '15px', fontWeight: 'bold' }}> Portfolio
            </ListItemText>
          ):( */}
            <ListItemText style={{ marginRight: '15px', fontWeight: 'bold', color:'#fff' }}> Portfolio
           </ListItemText>
          {/* )} */}
          
        </Link>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Link to="/" className={classes.dropdownLink}>
          {scrollE ? (
            <ListItemText style={{ marginRight: '15px' }}> Blog </ListItemText>
          ):(
            <ListItemText style={{ marginRight: '15px', color:'#fff' }}> Blog </ListItemText>
          )}
          
        </Link>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Link to="/sign-in" className={classes.dropdownLink}>
          {scrollE ? (
            <ListItemText style={{ marginRight: '15px' }}> Artwork </ListItemText>
          ):(
            <ListItemText style={{ marginRight: '15px', color:'#fff' }}> Artwork </ListItemText>
          )}
          
        </Link>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Link to="/rendering" className={classes.dropdownLink}>
        {/* {scrollE ? (
            <ListItemText style={{ marginRight: '15px' }}> Rendering </ListItemText>
          ):( */}
            <ListItemText style={{ marginRight: '15px', color:'#fff' }}> Rendering </ListItemText>
          {/* )} */}
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/walking-tours" className={classes.dropdownLink}>
        {/* {scrollE ? (
            <ListItemText style={{ marginRight: '15px' }}> Walking Tours </ListItemText>
          ):( */}
            <ListItemText style={{ marginRight: '15px', color:'#fff' }}> Walking Tours </ListItemText>
          {/* )} */}
        </Link>
      </ListItem>
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "black"
  ])
};
