const MonthlyBasic = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '1 Monthly Consultation',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '1 Month Supply of Testosterone Gel' ,
        checked: true,
    },
    {
        data: 'Gym Membership',
        checked: false
    },
    {
        data: '1 Personal Training Session',
        checked: false,
        additional: '(*Additional training sessions available at discount)'
    },
];

const MonthlyPremium = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '1 Monthly Consultation',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '1 Month Supply of Testosterone Gel' ,
        checked: true,
    },
    {
        data: 'Gym Membership',
        checked: true
    },
    {
        data: '1 Personal Training Session',
        checked: false,
        additional: '(*Additional training sessions available at discount)'
    },
];

const MonthlyPremiumPlus = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '1 Monthly Consultation',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '1 Month Supply of Testosterone Gel' ,
        checked: true,
    },
    {
        data: 'Gym Membership',
        checked: true
    },
    {
        data: '1 Personal Training Session',
        checked: true,
        additional: '(*Additional training sessions available at discount)'        
    },
];

const QuarterlyBasic = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '3 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '3 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: 'Gym Membership',
        checked: false
    },
    {
        data: '3 Personal Training Sessions',
        checked: false,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const QuarterlyPremium = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '3 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '3 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: 'Gym Membership',
        checked: true
    },
    {
        data: '3 Personal Training Sessions',
        checked: false,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const QuarterlyPremiumPlus = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '3 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '1 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '3 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: 'Gym Membership',
        checked: true
    },
    {
        data: '3 Personal Training Sessions',
        checked: true,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const YearlyBasic = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '12 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '4 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '12 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: '1 Year Gym Membership',
        checked: false
    },
    {
        data: '12 Personal Training Sessions',
        checked: false,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const YearlyPremium = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '12 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '4 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '12 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: '1 Year Gym Membership',
        checked: true
    },
    {
        data: '12 Personal Training Sessions',
        checked: false,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const YearlyPremiumPlus = [
    {
        data: 'Supervised Treatment',
        checked: true,
    },
    {
        data: '12 Monthly Consultations',
        checked: true,
        additional: '(Phone, Encrypted Messaging, Encrypted Video)'
    },
    {
        data: '4 Test Kit',
        checked: true,
        additional: '(Receive 1 test kit per quarter)'
    },
    {
        data: '12 Month Supply of Testosterone Gel' ,
        checked: true,
        additional: '(Delivered Monthly)'
    },
    {
        data: '1 Year Gym Membership',
        checked: true
    },
    {
        data: '12 Personal Training Sessions',
        checked: true,
        additional: '(1 Session per month, *Additional training sessions available at discount)'        
    },
];

const InitialTestKit = [
    {
        data: '1 Gel', 
        checked: true,

    },
    {
        data: 'Training Sessions by Facetime / Webchat / Phone Call',
        checked: true,

    },
    {
        data: 'Wellness Packages',
        checked: true,

    },
    {
        data: 'Delivery of Prescriptions and Topical Treatment',
        checked: true,

    }
]

export {
    MonthlyBasic,
    MonthlyPremium,
    MonthlyPremiumPlus,
    QuarterlyBasic,
    QuarterlyPremium,
    QuarterlyPremiumPlus,
    YearlyBasic,
    YearlyPremium,
    YearlyPremiumPlus,
    InitialTestKit,
  };