import {
  container,
  section,
  sectionDark,
  sectionDescription,
  title,
  whiteColor,
  mlAuto,
  hexToRgb,
  colorModePrimaryBackGround,
  colorModePrimaryText,
  colorModeSecondaryBackGround,
  colorModeSecondaryText,
  colorModeBasicText
} from "assets/jss/material-kit-pro-react.js";

const cardsStyle = {
  colorModePrimaryBackGround,
  colorModePrimaryText,
  colorModeSecondaryBackGround,
  colorModeSecondaryText,
  colorModeBasicText,
  section: {
    ...section,
    padding: "50px 0px"
  },
  sectionDark,
  container,
  sectionDescription,
  title: {
    ...title,
    textAlign: 'left',
    fontSize: '2.1rem'
  },
  description: {
    color: "rgba(" + hexToRgb(whiteColor) + ",0.76)"
  },
  imageContainer: {
    maxWidth: "1040px",
    marginTop: "-140px",
    position: "relative",
    height: "660px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      position: "absolute",
      right: "0px",
      top: "0px"
    }
  },
  imgHover:{
    "&:hover": {
      transform: "scale(1.1)",
      transition: "all 500ms ease-out",
    }
  },
  buttonCard: {
    marginTop: "30px",
    backgroundColor: "#af9a50",
    color: 'white'
  },
  mlAuto,
  sectionStyle: {
    padding: '150px 0px',
    backgroundSize: 'cover',
    backgroundPosition: 'center 70%',
  },
  divWidth: {
    width: '80%',
    margin: 'auto',   
  },
  divBgStyle: {
    padding: '30px 50px 50px 50px',
    boxShadow: '1px 1px 8px 0px #00000061'
}
};

export default cardsStyle;
