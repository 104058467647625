import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
// import Close from "@material-ui/icons/Close";
// import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import Button from "components/CustomButtons/Button.js";

import freeDemoStyle from "assets/jss/material-kit-pro-react/views/presentationSections/freeDemoStyle.js";
import SectionWork from "views/LandingPage/Sections/SectionWork";
// import bg9 from "assets/img/contact.jpg";


const useStyles = makeStyles(freeDemoStyle);

export default function SectionFreeDemo() {
  const classes = useStyles();
  return (
    <div className={classes.section} style={{ paddingTop: 0 }}>
      <div className={classes.container}>     
        <GridContainer justify="center">
          <GridItem
            md={12}
            sm={12}
            className={classes.mlAuto + " " + classes.mrAuto}
          >
             <div>
              <SectionWork />
            </div>           
          </GridItem>         
        </GridContainer>
      </div>
    </div>
  );
}
