/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionCards from "views/PresentationPage/Sections/SectionCards.js";
import Testimonials from "./Testimonials";
import SectionFreeDemo from "views/PresentationPage/Sections/SectionFreeDemo.js";


import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js";

const useStyles = makeStyles(aboutUsStyle);

export default function AboutUsPage() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                links={<HeaderLinks changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }} dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "black"
                }}
            />
            <Parallax
                image={require("assets/img/about.jpg")}
                className={classes.parallax}
            />

            <div className={classNames(classes.main, classes.mainRaised, classes.colorModePrimaryBackGround)}>
                <div className={classes.container}>
                    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
                        <h2 className={classes.title + ' ' + classes.colorModePrimaryText}> About </h2>
                        <GridContainer>
                            <GridItem
                                md={10}
                                sm={8}
                                className={classNames(classes.mrAuto, classes.mlAuto)}
                            >
                                <h5 className={classes.description + ' ' + classes.colorModeSecondaryText}>
                                We create spaces considering functionality & form to achieve aesthetics to satisfy the requirement of the project. The design integrates the cutting edge technology with discreet use of ecofriendly materials. Our design blends art and architecture, light and shadow, texture and colour to add value to the project by creating an ambience that projects the image of the client. Our design process addresses the major issues of budget, timeframe and best available technology as required for the specific project.
                                </h5>
                                {/* <h5 className={classes.description + ' ' + classes.colorModeSecondaryText}>
                                    With an individualised hormone replacement therapy and well being programme at Menergy. you will quickly experience a boost in body composition, body density/strength, libido, cognitive capacity. memory, mental energy, stamina, mood and well-being.
                                </h5>
                                <h5 className={classes.description + ' ' + classes.colorModeSecondaryText}>
                                    The in-depth testing that is done at Menergy along with regular follow ups with our experienced patient care coordinators, is part of the secret in creating the shortest route to an improved quality of life.
                                </h5>
                                <h5 className={classes.description + ' ' + classes.colorModeSecondaryText}>
                                    Wellness is our craft, we never stop looking after your health/we are transparent, ethical an passionate about what we do. We put you first and strive to provide the best care.
                                </h5> */}
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <div className={classes.sectionCards}>
                    <SectionCards />
                </div>
                <div>
                    <Testimonials />
                </div>
                <div>
                    <SectionFreeDemo />
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <GridContainer>
                            <GridItem sm={6} xs={12} md={9}>
                                <p>
                                {/* Copyright 2019, All rights reserved */}
                                </p>
                            </GridItem>
                            <GridItem sm={6} xs={12} md={3}>
                                <p className={classes.colorModePrimaryText}>
                                Powered by <a target="_Blank" href="http://nceptio.com" className={classes.colorModePrimaryText} alt="Best Web Developers"> Nceptio <img src="/nceptio_logo2.png" style={{ marginLeft: 10, width: 85}} /></a>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </div>
                }
            />
        </div>
    );
}
