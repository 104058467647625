import React from "react";
// react component for creating beautiful carousel
// import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
// import Muted from "components/Typography/Muted.js";
// import Warning from "components/Typography/Warning.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

// import dg2 from "assets/img/dg2.jpg";
import cardProfile1Square from "assets/img/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/card-profile4-square.jpg";

const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: false
  // };
  return (
    <div className="cd-section" {...rest}>
      {/* Testimonials 1 START */}
      <div
        className={classes.testimonials}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title + ' ' + classes.colorModePrimaryText}>Our Customers Love Us</h2>
              <h5 className={classes.description  + ' ' + classes.colorModeSecondaryText} style={{ textAlign: 'center' }}>
              You need more information? Check what other persons are saying about our product. They are very happy with their purchase.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1 + ' ' + classes.colorModeSecondaryBackGround}>
                <div className={classes.icon}>
                  <FormatQuote style={{ fill: '#333' }}/>
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription + ' ' + classes.tabDarkFont}>
                 I am thankful to Jay Cantrell Designs for their support and expertise in finding me an apartment in Dallas. For a first time buyer, they not only helped find a right house, they also assisted me with all legal and commercial concerns.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle} style={{ color: '#333' }}>Alec Thompson</h4>
                  <h6 className={classes.cardCategory + ' ' + classes.tabDarkFont}>@ALECTHOMPSON</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1 + ' ' + classes.colorModeSecondaryBackGround}>
                <div className={classes.icon}>
                  <FormatQuote style={{ fill: '#333' }}/>
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription + ' ' + classes.tabDarkFont}>
                   I am thankful to Jay Cantrell Designs for their support and expertise in finding me an apartment in Dallas. For a first time buyer, they not only helped find a right house, they also assisted me with all legal and commercial concerns.                    
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle} style={{ color: '#333' }}>Gina Andrew</h4>
                  <h6 className={classes.cardCategory + ' ' + classes.tabDarkFont}>@GINAANDREW</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img src={cardProfile4Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial className={classes.card1 + ' ' + classes.colorModeSecondaryBackGround}>
                <div className={classes.icon}>
                  <FormatQuote style={{ fill: '#333' }}/>
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription + ' ' + classes.tabDarkFont}>
                   I am thankful to Jay Cantrell Designs for their support and expertise in finding me an apartment in Dallas. For a first time buyer, they not only helped find a right house, they also assisted me with all legal and commercial concerns.
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle} style={{ color: '#333' }}>George West</h4>
                  <h6 className={classes.cardCategory + ' ' + classes.tabDarkFont}>@GEORGEWEST</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img src={cardProfile2Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>    
    </div>
  );
}
