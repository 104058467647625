import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// // @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Apps from "@material-ui/icons/Apps";

import bg9 from "assets/img/daydreams_Lark1.jpg"

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>      
      <div
        className={classes.features5}
        style={{ backgroundImage: `url(${bg9})`, backgroundAttachment: 'fixed' }}
      >
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={8}
            md={8}
            className={
              classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
            }
          >
            <h2 className={classes.title}>Interesting Facts About Dallas</h2>
            <h4 className={classes.factsDescription + ' ' + classes.colorModePrimaryText}> Texas is the second largest state in the United States by area and population. Geographically located in the South Central region of the country, Texas shares borders with the U.S. states of Louisiana to the east, Arkansas to the northeast, Oklahoma to the north, New Mexico to the west, and the Mexican states of Chihuahua, Coahuila, Nuevo León, and Tamaulipas to the southwest, and has a coastline with the Gulf of Mexico to the southeast.</h4>
          </GridItem>
          <div className={classes.container}>
            <GridContainer className={classes.gridContainer} justify="center">
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 className={classes.logoThemeTextColor + ' ' + classes.factsTitle}> Model Trains </h2>
                <h4 className={classes.factsDescription  + ' ' + classes.colorModePrimaryText}> Dallas has the largest model train exhibit </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 className={classes.logoThemeTextColor + ' ' + classes.factsTitle}> Theatre </h2>
                <h4 className={classes.factsDescription  + ' ' + classes.colorModePrimaryText}> The Dallas Theatre Center was designed by Frank Lloyd Wright </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 className={classes.logoThemeTextColor + ' ' + classes.factsTitle}> Music </h2>  
                <h4 className={classes.factsDescription  + ' ' + classes.colorModePrimaryText}> The Morton H, Meyerson Symphony Center was designed by I.M. Pei in 1989 </h4>
              </GridItem>
              <GridItem xs={12} sm={3} style={{ textAlign: 'center' }}>
                <h2 className={classes.logoThemeTextColor + ' ' + classes.factsTitle}> Preservation </h2>
                <h4 className={classes.factsDescription  + ' ' + classes.colorModePrimaryText}> Dallas has numerous historic districts preserving architecture from centuries past. It is also home to Old City Park that features some 3 dozen homes from the 19th Century. </h4>
              </GridItem>
            </GridContainer>
           
          </div>
        </GridContainer>
      </div>      
    </div>
  );
}
