import {
  container,
  section,
  grayColor,
  title,
  colorModePrimaryText,
  lightWhiteColor,
  colorModeSecondaryText
} from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
  container,
  colorModePrimaryText,
  colorModeSecondaryText,
  description: {    
    textAlign: "center"
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  features: {
    textAlign: "center !important",
    paddingTop: "80px",
    paddingBottom: "50px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em"
    }
  },
  title,
  customCardLayout: {
    boxShadow: '1px 1px 8px 0px #d3d3d3', 
    marginRight: 10, 
    marginBottom: 40, 
    height: '24rem', 
    borderBottom: '2px solid #cca64a',
    overflow: "hidden" 
  },

  // Dakr Theme

  customCardLayoutDark: {
    boxShadow: 'none',
    border: '1px solid',
    borderColor: lightWhiteColor,
  }
};

export default descriptionStyle;
