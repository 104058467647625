/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Footer from "components/Footer/Footer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";


import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import Package from "./Package";
import {
    MonthlyBasic,
    MonthlyPremium,
    MonthlyPremiumPlus,
    QuarterlyBasic,
    QuarterlyPremium,
    QuarterlyPremiumPlus,
    YearlyBasic,
    YearlyPremium,
    YearlyPremiumPlus
} from "./Data.js";

import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button.js";
// import cardBlog3 from "assets/img/img4.jpg";
// import Check from "@material-ui/icons/Check";
// import Close from "@material-ui/icons/Close";
// import Success from "components/Typography/Success.js";
// import Danger from "components/Typography/Danger.js";


const useStyles = makeStyles(shoppingCartStyle);

export default function PackageDetails() {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });
    const classes = useStyles();
    return (
        <div>
            <Header
                brand="Material Kit PRO React"
                links={<HeaderLinks changeColorOnScroll={{
                    height: 100,
                    color: "white"
                }} dropdownHoverColor="info" />}
                fixed
                color="transparent"
                changeColorOnScroll={{
                    height: 300,
                    color: "white"
                }}
            />

            <Parallax
                // image={require("assets/img/img4.jpg")}
                className={classes.parallax}
            />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{ paddingBottom: 50 }}>
                    <Card plain>
                        <CardBody plain>
                            <h2 className={classes.cardTitle} style={{ borderBottom: '1px solid #d3d3d3', paddingBottom: '40px' }}>Subscription Package Details</h2>
                            <h2 className={classes.cardTitle} style={{ fontSize: '1.3rem', marginTop: '4rem' }}>
                                Monthly Subscription Packages
                            </h2>
                            <h6 className={classes.cardTitle} style={{ color: 'gray' }}>
                                (3 Month Minimum)
                            </h6>
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Basic</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 120
                                            </h3>
                                            {MonthlyBasic.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 145
                                            </h3>
                                            {MonthlyPremium.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium Plus</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 200
                                            </h3>
                                            {MonthlyPremiumPlus.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                            </GridContainer>

                            <h2 className={classes.cardTitle} style={{ fontSize: '1.3rem', marginTop: '4rem' }}>
                                Quarterly Subscription Packages
                            </h2>
                            <h6 className={classes.cardTitle} style={{ color: 'gray' }}>
                                (1 Year Minimum)
                            </h6>

                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Basic</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 340
                                            </h3>
                                            {QuarterlyBasic.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 413
                                            </h3>
                                            {QuarterlyPremium.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium Plus</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 570
                                            </h3>
                                            {QuarterlyPremiumPlus.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                            </GridContainer>

                            <h2 className={classes.cardTitle} style={{ fontSize: '1.3rem', marginTop: '4rem' }}>
                                Yearly Subscription Packages
                            </h2>


                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Basic</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 1296
                                            </h3>
                                            {YearlyBasic.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 1560
                                            </h3>
                                            {YearlyPremium.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                                <GridItem xs={12} sm={4} md={4}>
                                    <Card pricing raised>
                                        <CardBody pricing >
                                            <h6 className={classes.cardCategory}>Premium Plus</h6>
                                            <h3 className={classes.cardTitle}>
                                                <small>&euro;</small> 2160
                                            </h3>
                                            {YearlyPremiumPlus.map((val) =>
                                                <Package data={val.data} checked={val.checked} additional={val.additional} />
                                            )}
                                        </CardBody>
                                        <CardFooter pricing className={classes.justifyContentCenter}>
                                            <Button color="rose" round>
                                                BUY
                                    </Button>
                                        </CardFooter>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <Footer
                content={
                    <div>
                        <GridContainer>
                            <GridItem sm={6} xs={12} md={9}>
                                <p>
                                {/* Copyright 2019, All rights reserved */}
                                </p>
                            </GridItem>
                            <GridItem sm={6} xs={12} md={3}>
                                <p>
                                Powered by <a  target="_Blank" href="http://nceptio.com" alt="Best Web Developers"> Nceptio <img src="/nceptio_logo2.png" style={{ marginLeft: 10, width: 85}} /></a>
                                </p>
                            </GridItem>
                        </GridContainer>
                    </div>
                }
            />
        </div>
    );
}
