const images = [
    {
        src: "src/assets/img/img1.jpeg",
    },
    {
        src: "src/assets/img/img1.jpeg"
    },
];

export {
    images
};