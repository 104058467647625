import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import uuid from 'uuid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Snackbar } from '@material-ui/core';
import { postRequestWithOutToken } from '../../services/signUp';
import { questionDetails, questionAnswerApi } from '../../services/Questions';
import Header from 'components/Header/Header';
import HeaderLinks from 'components/Header/HeaderLinks';

import image from "../../assets/img/img1.jpeg";


const styles = ({
    Logo: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 0 10px 0',
        // background: '#3f51b5',
        padding: '20px',

    },
    SignUpFormDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form_Control: {
        marginLeft: 0,
        marginRight: 0,
        color: '#283593',
        width: '25%',
    },
    margin_bottom: {
        marginBottom: 30
    },
    radio_button: {
        color: '#283593 !important',
    },
    formTitle: {
        textAlign: 'center',
        color: 'white'
    },
    fontBold: {
        fontWeight: 500,
        lineHeight: '80px',
        margin: '10px 0'
    },
    mainDiv: {
        margin: '30px 0',
        background: 'white',
        boxShadow: '1px 3px 11px 1px #0000003b',
        paddingBottom: 20
    },
    divLine: {
        backgroundColor: '#af9a50',
        height: '100px',
        width: '85%',
        marginTop: '-5%'
    },
    divWrap: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    bgImage: {
        // paddingTop: 150, 
        backgroundSize: "cover",
        backgroundPosition: "top center",
        height: '100vh',
        overflowY: 'auto'
    }
});


class Questions extends Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            questionAnswers: [],
            saveQuestion: false,
            openSnackBar: false,
        };
        // this.paramLanguage = this.props.location && this.props.location.state && this.props.location.state.lang;
        // this.lang = localStorage.getItem('language') || 'en';
    };

    componentWillMount() {
        let candidate_data = {
            "token": localStorage.getItem('token'),
            "candidate_id": localStorage.getItem('id'),
        }

        questionDetails(candidate_data).then((result) => {
            const modifiedQuestions = result.questions.map((question) => {
                const options = question.answer_option.split(',');
                return {
                    ...question,
                    options: options,
                    answer: '',
                }
            });
            this.setState({
                questionAnswers: modifiedQuestions
            })
        });
    }

    handleChange = (e) => {
        this.setState({
            question1: e.target.value
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    onSubmitQuestions = () => {
        const { questionAnswers } = this.state
        let errorIndex = '';
        questionAnswers.forEach((item, index) => {
            if (item.answer === null) {
                errorIndex = `${errorIndex} ${index + 1}`;
            }
        })
        if (errorIndex.length === 0) {
            const newData = questionAnswers.map((item) => {
                return {
                    questionnaire_id: item.id,
                    answer: item.answer
                }
            })
            const data1 = { question_answers: newData }
            let candidate_data = {
                "token": localStorage.getItem('token'),
                "candidate_id": localStorage.getItem('id'),
            }
            data1.token = localStorage.getItem('token')
            postRequestWithOutToken('/event', { 'event': 'click', value: 'Questionnaire' });
            questionAnswerApi(data1, candidate_data).then((res) => {
                if (!res.error) {
                    this.setState({
                        saveQuestion: true
                    })
                    this.props.history.push('/response', { lang: this.paramLanguage })
                }
            })
        } else {
            this.setState({ openSnackBar: true, questionAlert: errorIndex })
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            openSnackBar: false
        })
    }

    render() {
        const { questionAnswers, questionAlert, openSnackBar } = this.state
        const { classes } = this.props;
        return (
            <div>

                <div className={classes.bgImage} style={{
                    backgroundImage: "url(" + image + ")",
                }}>
                    <Header
                        color="transparent"
                        links={<HeaderLinks changeColorOnScroll={{
                            height: 100,
                            color: "white"
                        }} dropdownHoverColor="rose" />}
                    />
                    <Container maxWidth="md" style={{ marginTop: 100 }}>
                        <div className={classes.mainDiv}>
                            <div className={classes.divWrap}>
                                <div className={classes.divLine}>
                                    <div className={classes.formTitle}>
                                        <h2 className={classes.fontBold}>
                                            {/* {this.lang.includes('es') ? 'Cuestionario candidato' : 'Candidate Questionnaire'} */}
                                            Questionnaire
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={classes.Logo}>
                                    {/* <img src={logo} alt="img" style={{ height: 100, width: 200 }} /> */}
                                </div>

                            </div>
                            {/* <div className={classes.SignUpFormDiv}>
                                <h2 className="social-div-title">
                                    {this.lang.includes('es') ? 'Información profesional' : 'Professional Information'}
                                </h2>
                            </div> */}

                            <Container maxWidth="lg">
                                <Snackbar className="snackbar" message={`Please answer mandatory question. Question numbers are ${questionAlert}`} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} onClose={this.handleCloseSnackbar} autoHideDuration={6000} />
                                {questionAnswers.map((question, index) =>
                                    <div className={classes.margin_bottom}>
                                        <h4>
                                            {index + 1}. {question.question}
                                        </h4>

                                        {question.has_option === "2" ?
                                            <FormControl cmponent="fieldset" fullWidth >
                                                <RadioGroup
                                                    aria-label="CitizenValue"
                                                    name="CitizenValue"
                                                    value={question.answer}
                                                    onChange={(e) => {
                                                        questionAnswers[index].answer = e.target.value
                                                        this.setState({ questionAnswers })
                                                    }}
                                                    style={{ flexWrap: 'nowrap', flexDirection: 'row' }}
                                                >
                                                    {question.options.map((answer) =>
                                                        <FormControlLabel key={uuid.v1()}
                                                            value={answer}
                                                            control={<Radio classes={{ checked: classes.radio_button }} />}
                                                            label={answer}
                                                            className={classes.form_Control} />
                                                    )}
                                                </RadioGroup>
                                            </FormControl>
                                            : <> </>
                                        }

                                        {question.has_option === "1" ?
                                            <form autoComplete="off">
                                                <FormControl
                                                    fullWidth
                                                    className={classes.formControl}>
                                                    <TextField
                                                        select
                                                        open={this.open}
                                                        onClose={this.handleClose}
                                                        onOpen={this.handleOpen}
                                                        variant="outlined"
                                                        // label={this.lang.includes('es') ? 'Por favor seleccione respuesta' : 'Please Select Answer'}
                                                        label="Please Select Answer"
                                                        value={question.answer}
                                                        onChange={(e) => {
                                                            questionAnswers[index].answer = e.target.value
                                                            this.setState({ questionAnswers })
                                                        }}

                                                    >
                                                        <MenuItem value="">
                                                            {/* {this.lang.includes('es') ? 'Por favor seleccione respuesta' : 'Please Select Answer'} */}
                                                            Please Select Answer
                                                        </MenuItem>
                                                        {question.options.map((answer) =>
                                                            <MenuItem key={uuid.v1()} value={answer}>{answer}</MenuItem>
                                                        )}
                                                    </TextField>
                                                </FormControl>
                                            </form>
                                            : <> </>
                                        }

                                        {question.has_option === "0" ?
                                            <TextField
                                                fullWidth
                                                // label={this.lang.includes('es') ? 'Por favor complete la respuesta' : 'Please Fill Answer'}
                                                label="Please Fill Answer"
                                                margin="normal"
                                                variant="outlined"
                                                value={question.answer}
                                                onChange={(e) => {
                                                    questionAnswers[index].answer = e.target.value
                                                    this.setState({ questionAnswers })
                                                }}
                                            />
                                            : <> </>
                                        }
                                    </div>


                                )}
                                <div style={{ marginBottom: '20px', textAlign: 'center', marginTop: '20px' }}>
                                    <Button
                                        onClick={this.onSubmitQuestions}
                                        variant="contained"
                                        size="medium"
                                        style={{
                                            marginBottom: "10px",
                                            backgroundColor: '#af9a50',
                                            color: 'white'
                                        }} >
                                        {/* {this.lang.includes('es') ? 'Enviar' : 'Submit'} */}
                                        Submit
                                    </Button>
                                </div>
                            </Container>

                        </div>
                    </Container>

                </div>
            </div>
        );
    }
}

Questions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Questions);
