import {
  title,
  mrAuto,
  mlAuto,
  grayColor,
  colorModePrimaryText,
  colorModeSecondaryText,
} from "assets/jss/material-kit-pro-react.js";

const workStyle = {
  mrAuto,
  mlAuto,
  colorModePrimaryText,
  colorModeSecondaryText,
  section: {
    padding: "70px 0"
  },
  title,
  description: {
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  workStyleButton: {
    color: '#333',
    backgroundColor: '#fafaef'
  }
};

export default workStyle;
