import {
  container,
  title,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  mlAuto,
  description,
  cardTitle,
  grayColor,
  colorModePrimaryText,
  colorModePrimaryBackGround,
  colorModeSecondaryBackGround,
  colorModeSecondaryText,
} from "assets/jss/material-kit-pro-react.js";

const aboutUsStyle = {
  main,
  mainRaised,
  mrAuto,
  mlAuto,
  colorModePrimaryText,
  colorModeSecondaryBackGround,
  colorModePrimaryBackGround,
  colorModeSecondaryText,
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
     
  },
  textCenter: {
    textAlign: "center"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  aboutDescription: {
    padding: "50px 0 0 0"
  },
  description: {
    ...description,
    textAlign: 'justify'
  },
  sectionCards: {
    marginTop: 50
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block",
    "& img": {
      width: "100%"
    }
  },
  // description: {
  //   maxWidth: "150px"
  // },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: grayColor[1]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "150px",
    fontWeight: "300",
    fontSize: "1.125em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0"
    }
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButton: {
    margin: "0px",
    padding: "5px"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
  },
  appBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  indicator: {
    backgroundColor: '#af9a50'
  },
  tabImage: {
    width: '100%',
    height: '15rem'
  },
  tabCategory: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 60,
    marginRight: 60,
    border: 1,
    padding: 10,
    borderRadius: 5,
    justifyContent: 'center',
  },
  selectedTabCategory: {
    background: '#af9a50',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 60,
    marginRight: 60,
    //border: 1px solid,
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    justifyContent: 'center',
  }
};

export default aboutUsStyle;
