/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// // @material-ui/icons
// import Email from "@material-ui/icons/Email";
// import Favorite from "@material-ui/icons/Favorite";
// import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

import image from "assets/img/img1.jpeg";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";


const useStyles = makeStyles(loginPageStyle);

export default function LoginPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="transparent"
        // brand="Material Kit PRO React"
        links={<HeaderLinks changeColorOnScroll={{
          height: 100,
          color: "white"
      }} dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="success"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Sign In</h4>
                    {/* <div className={classes.socialLine}>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-facebook" />
                      </Button>
                      <Button
                        justIcon
                        color="transparent"
                        className={classes.iconButtons}
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fab fa-google-plus-g" />
                      </Button>
                    </div> */}
                  </CardHeader>
                  {/* <p className={classes.description + " " + classes.textCenter}>
                    Or Be Classical
                  </p> */}
                  <CardBody signup>
                    <div>
                      <TextField
                        fullWidth
                        label={'Email'}
                        name="email"
                        type="email"
                        // onChange={handleChange}
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                      // value={values.email}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        label={'Password'}
                        name="Password"
                        type="password"
                        // onChange={handleChange}
                        autoComplete="email"
                        margin="normal"
                        variant="outlined"
                      // value={values.email}
                      />
                    </div>
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button contained color="secondary" size="medium" style={{ marginBottom: 30, marginTop: 20, color: 'white' }}>
                      Sign In
                    </Button>
                  </div>
                  <div>
                    <div style={{ textAlign: 'center', color: '#000', marginBottom: 10 }}>
                      <span> Did not have any account?  <Link to="/signup" style={{ color: '#000' }}> Click Here </Link> </span>
                    </div>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>

        </div>
        <Footer
          className={classes.footer}
          content={
            <div>
              <div className={classes.left}>
                {/* <List className={classes.list}>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/?ref=mkpr-login"
                      target="_blank"
                      className={classes.block}
                    >
                      Creative Tim
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/presentation?ref=mkpr-login"
                      target="_blank"
                      className={classes.block}
                    >
                      About us
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="//blog.creative-tim.com/"
                      className={classes.block}
                    >
                      Blog
                    </a>
                  </ListItem>
                  <ListItem className={classes.inlineBlock}>
                    <a
                      href="https://www.creative-tim.com/license?ref=mkpr-login"
                      target="_blank"
                      className={classes.block}
                    >
                      Licenses
                    </a>
                  </ListItem>
                </List> */}
              </div>
              <div className={classes.right}>
                {/* &copy; {1900 + new Date().getYear()} , made with{" "} */}
                &copy; {1900 + new Date().getYear()} , Made by{" "}
                {/* <Favorite className={classes.icon} /> */}

                <a
                // href="https://www.creative-tim.com?ref=mkpr-login"
                // target="_blank"
                >
                  Jay Cantrell Designs
                </a>{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}
