/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

import image from "../../assets/img/img1.jpeg";

import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks";
import CardFooter from "components/Card/CardFooter";
import Package from "../Packages/Package.js";

import { InitialTestKit } from "../Packages/Data.js";

const useStyles = makeStyles(signupPageStyle);

export default function Assessment(props) {

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="transparent"
        links={<HeaderLinks changeColorOnScroll={{
          height: 100,
          color: "white"
      }} dropdownHoverColor="rose" />}
      />
      <div
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: '100vh',
          overflowY: 'auto'
        }}>
        <div className={classes.container} style={{ paddingTop: '22vh' }}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={10}>

              <Card className={classes.cardSignup}>
                <div className={classes.divWrap}>
                  <div className={classes.divLine}>
                    <h3 className={classes.cardTitle}>Test Kit Details</h3>
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                </div>
                <CardBody style={{ paddingTop: 100 }}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <div>
                        <div className={classes.flexBtn}>
                          <h4 className={classes.assessmentTitle}>
                            Have you taken self-assessment test?
                            </h4>
                          <Button color="rose" href="\payment" style={{ width: '50px', marginLeft: 30 }}> Yes </Button>
                          <Button color="rose" href="\self-assessment" style={{ width: '50px', marginLeft: 10 }}> No </Button>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card pricing plain>
                        <CardBody pricing plain>
                          <h4 className={classes.cardCategory}>Initial Test Kit</h4>
                          <hr />
                          <h1>
                            <small>&euro;</small> 65
                          </h1>
                          {InitialTestKit.map((val) =>
                            <Package data={val.data} checked={val.checked}/>
                          )}
                        </CardBody>
                        <CardFooter pricing className={classes.justifyContentCenter}>
                          <Button
                            href="\payment"
                            size="medium"
                            variant="contained"
                            style={{
                              marginTop: "30px",
                              backgroundColor: "#283593",
                              color: 'white',
                              padding: '10px 25px'
                            }} >
                            BUY
                          </Button>
                        </CardFooter>
                      </Card>
                    </GridItem>

                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>

      </div>
    </div>
  );
}
