/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import Icon from "@material-ui/core/Icon";
// // @material-ui/icons
// import Timeline from "@material-ui/icons/Timeline";
// import Code from "@material-ui/icons/Code";
// import Group from "@material-ui/icons/Group";
// import Face from "@material-ui/icons/Face";
// import Email from "@material-ui/icons/Email";
// import Check from "@material-ui/icons/Check";
// import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
// import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

// import image from "assets/img/bg7.jpg";
// import { Link } from "react-router-dom";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";



const useStyles = makeStyles(signupPageStyle);
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 6;

export default function SignUpPage({ ...rest }) {
  const [checked, setChecked] = React.useState([1]);
  const [monthSelect, setMonthSelect] = React.useState("");
  const [yearSelect, setYearSelect] = React.useState("");
  const [years, setYears] = React.useState(['2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033', '2034', '2035']);
  const [months, setMonth] = React.useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);


  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const handleMonthChange = event => {
    setMonthSelect(event.target.value);
  };

  const handleYearChange = event => {
    setYearSelect(event.target.value);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit PRO React"
        links={<HeaderLinks changeColorOnScroll={{
          height: 100,
          color: "white"
      }} dropdownHoverColor="rose" />}
      // {...rest}
      />
      <div
        // className={classes.pageHeader}
        style={{
          // backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          // height: '100vh'
        }}
      >
        <div className={classes.container} style={{ paddingTop: '25vh' }}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={10} md={8}>
              <Card className={classes.cardSignup}>
                <div className={classes.divWrap}>
                  <div className={classes.divLine}>
                    <h3 className={classes.cardTitle}>Payment Details</h3>
                  </div>
                </div>
                <CardBody style={{ paddingTop: 100 }}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={8}>
                      <form className={classes.form}>
                        {/* <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            placeholder: "Card Number..."
                          }}
                        />                         */}
                        <TextField
                          fullWidth
                          label={'Card Number'}
                          name="cardNumber"
                          margin="normal"
                          // variant="outlined"
                        // value={values.fullname}
                        // onChange={handleChange}
                        />                        
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <FormControl fullWidth className={classes.selectFormControl} variant="outlined" style={{ marginRight: '5px' }}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              variant="outlined"                              
                            >
                              Select Expiration Month
                            </InputLabel>
                            <Select
                              variant="outlined"
                              MenuProps={{
                                className: classes.selectMenu,
                                PaperProps: {
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                  },
                                },
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={monthSelect}
                              onChange={handleMonthChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "simple-select"
                              }}
                            >                             
                              {months.map((month) =>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={month}
                                >
                                  {month}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                          <FormControl fullWidth className={classes.selectFormControl} style={{ marginLeft: '5px' }}>
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              variant="outlined"
                            >
                              Select Expiration Year
                            </InputLabel>
                            <Select
                              variant="outlined"
                              MenuProps={{
                                className: classes.selectMenu,
                                PaperProps: {
                                  style: {
                                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                    width: 250,
                                  },
                                },
                              }}
                              classes={{
                                select: classes.select
                              }}
                              value={yearSelect}
                              onChange={handleYearChange}
                              inputProps={{
                                name: "simpleSelect",
                                id: "simple-select"
                              }}
                            >                             
                              {years.map((year) =>
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                  value={year}
                                >
                                  {year}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </div>
                        <TextField
                          fullWidth
                          label={'Security Code'}
                          name="cardNumber"
                          margin="normal"
                          // variant="outlined"
                        // value={values.fullname}
                        // onChange={handleChange}
                        /><TextField
                          fullWidth
                          label={'Card Holder Name'}
                          name="cardNumber"
                          margin="normal"
                          // variant="outlined"
                        // value={values.fullname}
                        // onChange={handleChange}
                        />
                        {/* <FormControlLabel
                          classes={{
                            label: classes.label
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                              }}
                              checked={checked.indexOf(1) !== -1 ? true : false}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a href="#pablo">terms and conditions</a>.
                            </span>
                          }
                        /> */}
                        <div className={classes.textCenter}>
                          {/* <Link to="/signup-second"> */}
                          <Button round color="primary">
                            Pay
                            </Button>
                          {/* </Link> */}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
